import { Link } from 'gatsby';
import React from 'react';

const classes = {
  wrapper: 'mb-6',
  name: 'font-semibold text-gray-900 pb-1',
  description: 'text-md text-gray-600 font-light mt-2 w-4/5',
};

const SummaryItem = ({ name, description, startEnd, firstDetail, secondDetail, jobTitle, link = false, internal = false }) => {
  let linkContent;
  //console.log("bryan check the job title: ", jobTitle);
  if (internal) {
    linkContent = <Link to={link}>{name}</Link>;
  } else {
    linkContent = <a target="_blank" rel="noreferrer" href={link}>{name}</a>;
  }
  if (firstDetail && secondDetail){
    return (
      <div className={classes.wrapper}>
        <h3
          className={`${classes.name} ${
            link ? 'hover:underline hover:text-black' : ''
          }`}
        >
          {link ? linkContent : name}
        </h3>
        <h4 class='startEnd font-semibold'>{startEnd}</h4>
        <h5>{jobTitle}</h5>
        <p className={classes.description}>{description}</p>
        <ul class="list-disc list-inside mt-3 mb-3 w-4/5">
          <li>{firstDetail}</li>
          <li>{secondDetail}</li>
        </ul>
        <hr class='w-1/2'></hr>
      </div>
    );
  } else {
    return (
      <div className={classes.wrapper}>
        <h3
          className={`${classes.name} ${
            link ? 'hover:underline hover:text-black' : ''
          }`}
        >
          {link ? linkContent : name}
        </h3>
        <h4 class='startEnd font-medium'>{startEnd}</h4>
        <h5>{jobTitle}</h5>
        <p className={classes.description}>{description}</p>
      </div>
    );
  }
};

export default SummaryItem;
